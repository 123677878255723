<template>
    <div>
        <page-header
            :title="$t('pages.closeAccount.emailPayoff.title')"
            data-testid="close-account-email-payoff-header"
            show-back
            @onClose="$router.go(-1)"
        />
        <div class="container">
            <div
                v-if="!isSent"
                data-testid="close-account-email-payoff-input-section"
            >
                <form-container
                    id="email"
                    ref="emailRef"
                >
                    <div
                        v-for="(email, index) in emails"
                        :key="`emailAddress${index}`"
                    >
                        <form-field
                            class="mt-2"
                            :data-testid="`close-account-email-payoff-email-form-${index}`"
                            v-model="email.value"
                            :id="`email-${index}`"
                            :key="`email-${index}`"
                            :name="`email-${index}`"
                            :placeholder="$t('pages.closeAccount.emailPayoff.placeholder')"
                            :validation="`email${index === 0 ? '|required' : ''}`"
                            input-type="email"
                            inputmode="email"
                        />
                    </div>
                </form-container>
                <div
                    class="icon-button mt-2"
                    style="display: inline-block"
                    data-testid="close-account-email-payoff-add-email"
                    type="button"
                    @click="addNewEmailAddress"
                >
                    <img
                        src="@/assets/images/components/forms/buttonAddIcon.svg"
                        height="24"
                        width="24"
                        alt="Add Email"
                    >
                    <span class="fw-bold">
                        {{ $t('pages.closeAccount.emailPayoff.addEmail') }}
                    </span>
                </div>
                <div
                    v-show="error"
                    class="alert alert-warning text-center mt-1"
                    role="alert"
                >
                    <span>
                        {{ error }}
                    </span>
                </div>
                <base-button
                    data-testid="close-account-email-payoff-send-button"
                    button-classes="btn btn-primary mt-3"
                    @click="sendPayoffQuoteToEmail"
                    :submitting="isSending"
                >
                    Send Email
                </base-button>
            </div>
            <div
                v-else
                class="text-center"
                data-testid="close-account-email-payoff-confirm-section"
            >
                <img
                    class="mt-4"
                    src="@/assets/images/pages/card/EmailSent.svg"
                    :alt="$t('pages.closeAccount.emailPayoff.sendConfirmTitle')"
                    height="112"
                    width="112"
                >
                <h5
                    class="mt-2"
                    data-testid="close-account-email-payoff-confirm-title"
                >
                    <span class="fw-bold">
                        {{ $t('pages.closeAccount.emailPayoff.sendConfirmTitle') }}
                    </span>
                </h5>
                <div
                    class="text-muted mt-1"
                    data-testid="close-account-email-payoff-confirm-text"
                >
                    <span v-html="confirmMessage" />
                </div>
                <base-button
                    button-classes="btn btn-primary mt-3"
                    data-testid="close-account-email-payoff-continue-button"
                    @click="handleClickButton"
                >
                    Continue
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import { navigation } from '@/mixins/navigation'
    import BaseButton from '@/components/base/BaseButton'
    import format from '@/mixins/format'
    import { sendAccountPayoffQuotePdfToEmail } from '@/services/api'
    import { i18n } from '@/utils/i18n'
    import FormField from '@/components/base/FormField'
    import FormContainer from '@/components/base/FormContainer'
    import { RouteNames } from '@/routes/router'
    import generic from '@/utils/generic'
    import { mapGetters } from 'vuex'

    export default {
        name: 'CloseAccountEmailPayoff',
        mixins: [format, navigation],
        components: {
            PageHeader,
            BaseButton,
            FormField,
            FormContainer,
        },
        mounted: async function () {
            if (this.isZeroBalancePayoff) {
                await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_WIRE })
                return
            }
            this.$logEvent('view_close_account_email_payoff')
        },
        data() {
            return {
                isSending: false,
                isSent: false,
                error: '',
                emails: [{ value: '' }],
                confirmMessage: '',
            }
        },
        computed: {
            ...mapGetters({
                billingDay: 'billingDay',
                isZeroBalancePayoff: 'isZeroBalancePayoff',
            }),
        },
        methods: {
            sendPayoffQuoteToEmail: async function () {
                this.$logEvent('click_close_account_email_payoff_quote')
                try {
                    this.confirmMessage = ''
                    this.error = ''
                    this.isSent = false
                    const isValid = await this.$refs.emailRef.validateAll()
                    if (!isValid) {
                        return
                    }
                    this.isSending = true
                    await sendAccountPayoffQuotePdfToEmail(
                        generic.getCurrentJSDateInNycTz(),
                        generic.getGivenFutureOrCurrentDayJSDateInNycTz(this.billingDay),
                        this.emails.filter((email) => email.value !== '').map((email) => email.value)
                    )
                    this.isSending = false
                    this.isSent = true
                    this.confirmMessage = i18n.t('pages.closeAccount.emailPayoff.sendConfirmText', { emails: this.emails.map((email) => email.value).join(', ') })
                } catch (e) {
                    this.isSending = false
                    this.error = i18n.t('pages.closeAccount.emailPayoff.sendError')
                }
            },
            addNewEmailAddress: function () {
                this.emails.push({
                    value: '',
                })
            },
            handleClickButton: async function () {
                await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_PAYOFF })
            },
        },
    }
</script>
